export default class Proposta {
  constructor(
    id = null,
    anoReferencia = null,
    mesReferencia = null,
    matricula = {},
    rubrica = {},
    contrato = null,
    dataProcessamento = null,
    valorParcela = null,
    valorTotal = 0,
    prazoRestante = null,
    prazoTotal = null,
    obs = null,
    certidao = null,
    ip = null,
    on_line = false,
    statusProposta = {},
    tipo = null,
    cadastradoEm = null,
    cadastradoPor = {},
    alteradoEm = null,
    alteradoPor = null,
    auditMetadata = {},
    carencia = 0,
    compraDividaId = null,
  ) {
    this.id = id
    this.anoReferencia = anoReferencia
    this.mesReferencia = mesReferencia
    this.matricula = matricula
    this.rubrica = rubrica
    this.contrato = contrato
    this.dataProcessamento = dataProcessamento
    this.valorParcela = valorParcela
    this.valorTotal = valorTotal
    this.prazoRestante = prazoRestante
    this.prazoTotal = prazoTotal
    this.obs = obs
    this.certidao = certidao
    this.ip = ip
    this.on_line = on_line
    this.statusProposta = statusProposta
    this.tipo = tipo
    this.cadastradoEm = cadastradoEm
    this.cadastradoPor = cadastradoPor
    this.alteradoEm = alteradoEm
    this.alteradoPor = alteradoPor
    this.auditMetadata = auditMetadata
    this.carencia = carencia
    this.compraDividaId = compraDividaId
  }
}
